import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Box, Position } from "../../components/atoms/box";
import { IconInstagram } from "../../components/atoms/icon";
import { Text } from "../../components/atoms/typography";
import { Horizontal } from "../../components/gls/horizontal";
import { Vertical } from "../../components/gls/vertical";
import { Section } from "../../components/templates/page/section/section";
import { SectionIntroLayout } from "../../components/templates/page/section/section-intro";
import { StudioExternalLink, StudioSectionText, StudioTextLink } from "./studio-components";
import { WaterDrops } from "./studio-decorative";

export const StudioCollaborators = () => (
  <Background>
    <Section seamless pt={"3xl"} pb={"4xl"} position={"relative"} isolation={"isolate"}>
      <Position position={"relative"} zIndex={1}>
        <SectionIntroLayout
          left={
            <StudioSectionText maxW={450} overflowWrap={"break-word"} mb={"lg"}>
              The Creative Team & Collaborators
            </StudioSectionText>
          }
          right={
            <Box mt={10} pr={{ sm: 0, lg: 30 }}>
              <Individual
                firstName={"Sarah"}
                lastName={"Ceravolo"}
                title={"Chief Creative Officer (CCO) of Hydrogen Fuels Australia"}
                link={"https://www.convolodesign.com/"}
                instagramLink={"https://www.instagram.com/convolo_design/"}
                maxWidth={450}
                image={
                  <StaticImage
                    src={`../../assets/images/team/sarah-ceravolo.png`}
                    objectFit={"contain"}
                    placeholder="blurred"
                    alt="Sarah Ceravolo"
                    width={315}
                  />
                }
                description={
                  <Text maxWidth={450}>
                    Sarah is the Chief Creative Officer (CCO) of H2FA, where she explores the transfiguration of fossil
                    fuel to clean energy and the great potential for a positive urban and environmental impact through
                    design. A designer living and working in Melbourne, Australia, she is also the founder of both{" "}
                    <StudioTextLink href={"https://www.convolodesign.com"}>Convolo Design</StudioTextLink> and{" "}
                    <StudioTextLink href={"https://www.hscaled.com"}>H_SCALED</StudioTextLink>. Sarah promotes an
                    abstract, non-stylistic design ethos in order to think divergently and envisage solutions toward a
                    sustainable and liveable future. Drawing on her architectural background, one of Sarah’s main
                    focuses is to discover places for the human scale relative to the integration of technology into the
                    built environment.
                  </Text>
                }
              />
            </Box>
          }
        />
      </Position>

      <Position position={"absolute"} top={{ sm: 620, lg: 1 }} left={{ sm: 250, lg: 375 }} zIndex={0}>
        <WaterDrops />
      </Position>
    </Section>
  </Background>
);

const Background = styled.div({
  background:
    "linear-gradient(176.75deg, #4C82E3 8.82%, #2CA5C4 64.18%, rgba(55, 170, 194, 0.76) 85.28%, rgba(50, 89, 251, 0.71) 110.06%)",
});

const Individual = (props: {
  firstName: string;
  lastName: string;
  title: string;
  description: React.ReactNode;
  image: React.ReactNode;
  link: string;
  instagramLink: string;
  maxWidth?: string | number;
}) => (
  <Vertical horizontalAlign={"left"} maxWidth={props.maxWidth}>
    {props.image}
    <Text fontSize={"md"} fontWeight={"bold"} textTransform={"uppercase"}>
      {props.firstName} {props.lastName}
    </Text>
    <Text fontSize={"sm"} fontWeight={"bold"} textTransform={"uppercase"}>
      {props.title}
    </Text>
    {props.description}
    <Horizontal verticalAlign={"center"}>
      <StudioExternalLink href={props.link}>Learn more about {props.firstName}</StudioExternalLink>
      <IconInstagram size={20} color={"icywaters"} action={{ as: "a", target: "_blank", href: props.instagramLink }} />
    </Horizontal>
  </Vertical>
);
